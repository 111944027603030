<template>
  <div class="row justify-center full-height bg-secondary">
    <div class="full-height col-md-9 col-sm-12 q-px-md q-pt-md">
      <div class="flex justify-end">
        <q-btn size="md" icon="add" color="accent" class="q-mb-md" :to="{ name: 'new_project' }">{{ $t('Nuovo progetto') }}</q-btn>
      </div>
      <q-table
        style="max-height: 90%"
        :title=" $t('Progetti')"
        :data="data"
        :columns="columns"
        row-key="id"
        @row-click="onRowClick"
        :pagination.sync="pagination"
        :loading="loading"
        :filter="filter"
        @request="onRequest"
        binary-state-sort
        :rows-per-page-label="$t('Righe per pagina')"
        :pagination-label="(firstRowIndex, endRowIndex, totalRowsNumber) => { return firstRowIndex + ' - ' + endRowIndex + ' ' + $t('di') + ' ' + totalRowsNumber }"
      >
      <template v-slot:top-right>
        <q-input borderless dense debounce="300" v-model="filter" placeholder="Cerca">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body-cell-actions="props">
        <q-td :props="props">
          <q-btn color="warning" size="sm" @click.stop="printProject(props)">{{ $t('Stampa planimetria')}}</q-btn>
          <q-btn color="negative" size="sm" class="q-ml-md" @click.stop="deleteProject(props)">{{ $t('Elimina')}}</q-btn>
        </q-td>
      </template>
    </q-table>
    </div>
  </div>
</template>

<script>
import Project from 'src/store/models/Project'
import User from 'src/store/models/User'
import moment from 'moment'

export default {
  name: 'Projects',
  data () {
    return {
      filter: '',
      loading: false,
      pagination: {
        sortBy: 'updated_at',
        descending: true,
        page: 1,
        rowsPerPage: 30,
        rowsNumber: 1
      },
      columns: [
        {
          name: 'name',
          required: true,
          label: this.$t('Nome'),
          align: 'left',
          field: row => row.name,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'updated_at',
          required: true,
          label: this.$t('Ultima modifica'),
          align: 'right',
          field: row => moment(row.created_at).format('DD/MM/YYYY'),
          sortable: true
        },
        {
          name: 'actions',
          align: 'right'
        }
      ],
      data: []
    }
  },
  computed: {
    me () {
      return User.me()
    }
  },
  methods: {
    onRowClick (evt, row) {
      this.$router.push({ name: 'project_edit', params: { id: row.id } })
    },
    printProject ({ row }) {
      const routeData = this.$router.resolve({ name: 'project_print', target: '_blank', params: { id: row.id } })
      window.open(routeData.href, '_blank')
    },
    deleteProject ({ row }) {
      this.$q.dialog({
        title: this.$t('Conferma eliminazione'),
        message: this.$t('Sei sicuro di voler cancellare questo progetto?'),
        color: 'negative',
        ok: this.$t('Elimina'),
        cancel: true
      }).onOk(() => {
        this.$q.loading.show()
        Project.find(row.id).$delete()
          .then(() => {
            this.onRequest({
              pagination: this.pagination,
              filter: undefined
            })
            this.$q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check',
              message: this.$t('Progetto eliminato con successo'),
              position: 'top'
            })
          })
          .finally(() => {
            this.$q.loading.hide()
          })
      })
    },
    onRequest (props) {
      const { sortBy, descending } = props.pagination
      const filter = props.filter

      this.loading = true

      Project.api().get(`/api/companies/${this.me.company_id}/projects`, {
        dataKey: 'data',
        params: {
          ...props.pagination,
          per_page: props.pagination.rowsPerPage,
          descending: descending ? 1 : 0,
          filter
        }
      }).then(result => {
        this.pagination.page = result.response.data.current_page
        this.pagination.rowsPerPage = result.response.data.per_page
        this.pagination.sortBy = sortBy
        this.pagination.descending = descending
        this.pagination.rowsNumber = result.response.data.total
        this.data = result.response.data.data
        this.loading = false
      })
    }
  },
  mounted () {
    // get initial data from server (1st page)
    this.onRequest({
      pagination: this.pagination,
      filter: undefined
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
